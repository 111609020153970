import AuthData from "./AuthData"
import OrgStore from './OrgStore'
import EventsStore from "./EventsStore";
import ShiftsStore from "./ShiftsStore";
class RootStore {
    // Связываем хранилища
    constructor() {
        this.orgStore = new OrgStore(this)
        this.shiftsStore = new ShiftsStore(this)
        this.eventsStore = new EventsStore(this)
    }

    authData = new AuthData()
}

const stores = new RootStore()

export default stores;