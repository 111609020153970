import { makeAutoObservable, runInAction, toJS } from "mobx";
import Api from "../utils/api/api";

const api = new Api()

export default class ShiftsStore {
    activeSessions = []
    requests = []
    rootStore

    constructor(instance) {
        this.rootStore = instance
        makeAutoObservable(this, {}, {deep: true})
    }

    getRequests = async () => {
        try {
            const data = await api.getData('org/workaccessrequest')

            runInAction(() => {
                this.setRequests(data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    getActiveSessions = async () => {
        try {
            const data = await api.getData('org/workshiftsession/active')

            runInAction(() => {
                this.setActiveSessions(data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    setRequests(data) {
        const requests = data?.map(r => {
            return {
                ...r,
                worker: this.rootStore.orgStore.profiles?.find(({id}) => id === r.userId)
            }
        })

        this.requests = requests
    }

    setActiveSessions(data) {
        const active = data?.map(d => {
            return {
                ...d,
                worker: this.rootStore.orgStore.profiles?.find(({workShiftId}) => workShiftId === d.workShiftId)
            }
        })
        this.activeSessions = active
    }

    finishSession = (action, id) => {
        api.changeData('org/workshiftsession', action, id)
    }

    approveSession = (action, id) => {
        const session = this.requests.find(s => s.id === id)
        const index = this.requests.findIndex(r => r.id === id)
        this.requests.splice(index, 1)

        session.isActive = true
        this.activeSessions = [session, ...this.activeSessions]

        api.changeData('org/workaccessrequest', action, id)
    }

    disapproveSession = (action, id) => {
        const index = this.requests.findIndex(s => s.id === id)

        this.requests.splice(index, 1)

        api.changeData('org/workaccessrequest', action, id)
    }
}