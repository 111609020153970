import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { getDate } from "date-fns";
import { getMonth, getQueryDate, getWord } from "../../helpers/helpers";

import "./tables.scss";

import SelectMenu from "../Select/SelectMenu";
import PersonModal from "../PersonCard/PersonModal";

const Tables = ({ monthsData, months, isLoggedIn }) => {
  const [selectedOption, setSelectedOption] = useState({
    month: "Загружаю...",
  });

  const [totalMonths, setTotalMonths] = useState([]);

  const [monthData, setMonthData] = useState({
    workers: [],
    days: [],
  });

  useEffect(() => {
    if (months && months.length) {
      setSelectedOption({
        month: months[0],
      });

      setTotalMonths(months);

      const data = Object.values(
        monthsData.find((obj) => Object.keys(obj)[0] === months[0])
      )[0];

      setMonthData((monthData) => ({
        ...monthData,
        workers: data.workers,
        days: data.days,
      }));
    }
  }, [months, monthsData]);

  const handleSelectedOption = (month) => {
    setSelectedOption({
      month: month,
    });

    const data = Object.values(
      monthsData.find((obj) => Object.keys(obj)[0] === month)
    )[0];

    setMonthData((monthData) => ({
      ...monthData,
      workers: data.workers,
      days: data.days,
    }));
  };

  const [todayMonth, setTodayMonth] = useState(null);
  const [todayIndexInMonth, setTodayIndexInMonth] = useState(0);
  const [today, setToday] = useState(null);

  useEffect(() => {
    const now = new Date();
    const numOfDay = getDate(now);
    const dayIndex = numOfDay - 1;
    const month = getWord(getMonth(now)).slice(0, -3);
    setTodayMonth(month);
    setTodayIndexInMonth(dayIndex);
    const queryToday = getQueryDate(today);
    setToday(queryToday);
  }, []);

  const [showModal, setShowModal] = useState(false);

  const [queryParams, setQueryParams] = useState({
    deviceId: null,
    fromDT: null,
    worker: null,
  });

  const isQueryParams = Object.values(queryParams).every((p) => p);

  const handleShowModal = (e, devId, day, worker) => {
    // В случае, если в ячейке пусто, то выходим из функции
    if (!e.target.innerHTML) return;

    setShowModal(true);

    setQueryParams({
      deviceId: devId,
      fromDT: day.date,
      worker: worker,
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return isLoggedIn ? (
    <div className="tables-container">
      <table className="tables tables_margin0">
        <caption className="caption">
          <div className="d-flex align-items-center table__caption">
            <h4 className="tables__title title">Табели сотрудников</h4>
            <div className="tables__select">
              <SelectMenu
                selectedOption={selectedOption}
                options={totalMonths}
                handleSelectedOption={handleSelectedOption}
              />
            </div>
            <div className="search-container">
              <input
                type="text"
                placeholder="Поиск по имени или табелю"
                className="search-container__input"
              />
            </div>
          </div>
        </caption>
        <thead className="tables__head">
          <tr>
            <th className="head" scope="row"></th>
            {monthData.days.map((d, i) => {
              return (
                <th key={d.date} scope="row">
                  <div
                    className={`${
                      todayMonth === selectedOption.month &&
                      i === todayIndexInMonth
                        ? "head__item head__item_today"
                        : ""
                    }`}
                  >
                    {todayMonth === selectedOption.month &&
                    i === todayIndexInMonth ? (
                      <div className="head head__item head__item_today-inner text-center">
                        {d.day}
                      </div>
                    ) : (
                      <div className="head head__item text-center">{d.day}</div>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {monthData.workers.map((w) => {
            return (
              <tr key={w.id} className="tables tables__row">
                <th className="head head__person">
                  {w.name} {w.surname}
                </th>
                {monthData.days.map((d, i) => {
                  return (
                    <td key={w.id + d.date}>
                      <div
                        onClick={(e) => handleShowModal(e, w.deviceId, d, w)}
                        className={`d-flex align-items-center justify-content-center
                                                        ${
                                                          todayMonth ===
                                                            selectedOption.month &&
                                                          i > todayIndexInMonth
                                                            ? "day-container day-container_no-data"
                                                            : todayMonth ===
                                                                selectedOption.month &&
                                                              i ===
                                                                todayIndexInMonth
                                                            ? "day-container day-container_today"
                                                            : "day-container"
                                                        }
                                                        ${
                                                          selectedOption.month ===
                                                            todayMonth &&
                                                          i !==
                                                            todayIndexInMonth &&
                                                          w?.sessionsHistory?.find(
                                                            (s) =>
                                                              s.date === d.date
                                                          )?.hasAlerts
                                                            ? "alerts"
                                                            : ""
                                                        }
                                                        ${
                                                          selectedOption.month !==
                                                            todayMonth &&
                                                          w?.sessionsHistory?.find(
                                                            (s) =>
                                                              s.date === d.date
                                                          )?.hasAlerts
                                                            ? "alerts"
                                                            : ""
                                                        }
                                                        ${
                                                          selectedOption.month ===
                                                            todayMonth &&
                                                          i !==
                                                            todayIndexInMonth &&
                                                          w?.sessionsHistory?.find(
                                                            (s) =>
                                                              s.date === d.date
                                                          )?.hasCriticalAlerts
                                                            ? "critical-alerts"
                                                            : ""
                                                        }
                                                        ${
                                                          selectedOption.month !==
                                                            todayMonth &&
                                                          w?.sessionsHistory?.find(
                                                            (s) =>
                                                              s.date === d.date
                                                          )?.hasCriticalAlerts
                                                            ? "critical-alerts"
                                                            : ""
                                                        }
                                                        ${
                                                          w?.datesWithData?.some(
                                                            (data) =>
                                                              data === d.date
                                                          ) ||
                                                          w?.sessionsHistory?.some(
                                                            (s) =>
                                                              s.date === d.date
                                                          )
                                                            ? " day-container_data"
                                                            : ""
                                                        }
                                                        ${
                                                          selectedOption.month ===
                                                            todayMonth &&
                                                          i ===
                                                            todayIndexInMonth
                                                            ? " day-container_no-cursor"
                                                            : ""
                                                        }
                                                        }
                                                        `}
                      >
                        {todayMonth === selectedOption.month &&
                        i >= todayIndexInMonth ? (
                          <div className="inner-block"></div>
                        ) : w.sessionsHistory.find((s) => s.date === d.date)
                            ?.time ? (
                          w.sessionsHistory.find((s) => s.date === d.date)?.time
                        ) : (
                          w.datesWithData.find((data) => data === d.date) &&
                          "Н/Д"
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {isQueryParams && (
        <PersonModal
          key={queryParams.fromDT + "-" + queryParams.worker.deviceId}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          queryParams={queryParams}
        />
      )}
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default inject(({ orgStore, authData }) => {
  const { monthsData, months } = orgStore;
  const { isLoggedIn } = authData;

  return {
    isLoggedIn,
    monthsData,
    months,
  };
})(observer(Tables));
