import { makeAutoObservable } from "mobx";

export default class AuthData {
    initialToken = localStorage.getItem('token')
    token = this.initialToken
    isLoggedIn = !!this.token

    constructor() {
        makeAutoObservable(this)
    }

    logIn = (token) => {
        this.token = token
        localStorage.setItem('token', token)
        this.isLoggedIn = !!token
    }

    logOut = () => {
        this.token = ''
        localStorage.removeItem('token')
    }
}