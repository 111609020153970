import React, { useState, useEffect } from "react";
import { useWorkerEvents } from "../../hooks/useWorkerEvents";
import "./card.scss";
import PersonContainer from "./PersonContainer";
import ChartContainer from "../ChartContainer";

import {
  getWord,
  sessionDuration,
} from "../../helpers/helpers";

const PersonCard = ({ worker, fromDT }) => {
  const fullname = getWord(worker?.name) + " " + getWord(worker?.surname);
  const position = getWord(worker?.position);

  const [session, setSession] = useState({});

  const [activeSessionDuration, setActiveSessionDuration] = useState("");

  useEffect(() => {
    if (worker && worker.session) {
      setSession(worker.session)

      const now = new Date();
      const duration = sessionDuration(new Date(worker.session.dateBegin), now);

      duration && setActiveSessionDuration(duration);
    }
  }, [worker]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
  
      const duration = session.dateBegin
        ? sessionDuration(new Date(session.dateBegin), now)
        : "";
      setActiveSessionDuration(duration);
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [session])

  const devID = worker?.deviceId;

  // Используем отдельно вынесенный кастомный хук для получения событий для нижнего таймлайна
  const data = useWorkerEvents(fromDT, devID);

  const [
    workerEvents,
    handleIsTriggersEnabled,
    triggers,
    isTriggersEnabled,
    totalEvents,
  ] = data;

  return (
    <div className="card d-flex flex-column justify-content-between border-0">
      <div className="card__header d-flex justify-content-between align-items-center">
        <PersonContainer
          fullname={fullname}
          position={position}
          session={session}
          activeSessionDuration={activeSessionDuration}
        />
        <div className="shift-events align-self-start">
          <ul className="shift-events__list list d-flex justify-content-between">
            {isTriggersEnabled &&
              triggers.map((t) => {
                return (
                  <li key={t.id} className="list__item">
                    <div
                      className={`${
                        t.critical ? "icon icon_critical" : "icon"
                      }`}
                    >
                      icon
                    </div>
                    <div
                      className={`${
                        t.critical ? "trigger trigger_critical" : "trigger"
                      }`}
                    >
                      {t.action}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div className="card__body">
        <div className="charts-container">
          <ChartContainer
            key={fromDT + "-" + worker.deviceId}
            worker={worker}
            fromDT={fromDT}
            handleIsTriggersEnabled={handleIsTriggersEnabled}
            workerEvents={workerEvents}
            totalEvents={totalEvents}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonCard;
