import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { observer, inject } from "mobx-react";

import "./header.scss";
import logo from "../../assets/images/logo.svg";
import info from "../../assets/images/info.svg";
import alert from "../../assets/images/alert.svg";
import vector from "../../assets/images/vector.svg";

import Alert from "../Alert";

import { getWord } from "../../helpers/helpers";

const Header = ({
  isLoggedIn,
  dangerStatus,
  emergencyMessage,
  dangerEvent,
  workers,
}) => {
  const currentLocation = useLocation();

  const { pathname } = currentLocation;

  const [dangerEventData, setDangerEventData] = useState(null);

  const worker = dangerStatus
    ? workers?.find(({ deviceId }) => deviceId === dangerEvent.deviceId)
    : "";

  useEffect(() => {
    const eventData = {
      worker: worker && getWord(worker.name) + " " + getWord(worker.surname),
      type: emergencyMessage,
      objectName: worker && worker.objectName,
    };

    setDangerEventData(eventData);
  }, [dangerStatus, workers, emergencyMessage, worker]);

  return (
    <div className="header">
      <div className="header__wrapper">
        <header className="d-flex justify-content-between align-items-center">
          <nav>
            <ul className="menu-list">
              {isLoggedIn && (
                <>
                  <li>
                    <NavLink
                      to="/shift"
                      className={pathname === "/shift" ? "active" : ""}
                    >
                      Выход на смену
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/"
                      className={pathname === "/" ? "active" : ""}
                    >
                      Сотрудники
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/events" className="disabled">
                      События
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/tables"
                      className={pathname === "/tables" ? "active" : ""}
                    >
                      Табели
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/activity"
                      className={pathname === "/activity" ? "active" : ""}
                    >
                      Активность
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/devices"
                      className={pathname === "/devices" ? "active" : ""}
                    >
                      Устройства
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </nav>
          <div className="position-absolute start-50">
            <NavLink to={!isLoggedIn ? `/login` : "/"}>
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <div>
            {isLoggedIn && (
              <NavLink to="/">
                {dangerStatus ? (
                  <img src={alert} alt="alert" />
                ) : pathname === "/" ? (
                  <img src={vector} alt="employees" />
                ) : (
                  <img src={info} alt="info" />
                )}
              </NavLink>
            )}
          </div>
        </header>
      </div>
      {isLoggedIn && dangerStatus && <Alert eventData={dangerEventData} />}
    </div>
  );
};

export default inject(({ authData, eventsStore, orgStore }) => {
  const { isLoggedIn } = authData;
  const { events, dangerStatus, emergencyMessage, dangerEvent } = eventsStore;
  const { workers } = orgStore;

  return {
    isLoggedIn,
    events,
    dangerStatus,
    emergencyMessage,
    dangerEvent,
    workers,
  };
})(observer(Header));
