import React, { useState } from "react";
import { Form } from 'react-bootstrap';
import styles from './formlogin.module.scss'
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom'

const FormLogin = ({
    isLoggedIn,
    logIn
}) => {
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const handleChange = (e) => {
        const value = e.target.value
        const type = e.target.type
        setFormData({
            ...formData,
            [type]: value
        })
        // добавить валидацию
    }

    const submitHandler = (e) => {
        e.preventDefault();
        if (!isLoggedIn) {
            fetch('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyA4cACXVmsm50yvpiVRVcDoQ4hlp0nMIaw', {
                method: 'POST',
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password,
                    returnSecureToken: true
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw new Error('Что-то пошло не так')
                    }
                })
                .then(result => {
                    logIn(result.idToken)

                    setFormData({
                        email: '',
                        password: ''
                    })

                    navigate('/')
                })
                .catch(err => console.log(err))
        }
    }

    return (
        <div className={`${styles.container} d-flex align-items-center justify-content-center`}>
            <Form className={`${styles.form} d-flex flex-column align-items-center`}
                onSubmit={submitHandler}>
                <h4 className={styles.title}>Вход в систему</h4>
                <Form.Group className={styles.login}>
                    <input type="email" placeholder="Логин" className={styles.input} onChange={handleChange} value={formData.email} />
                </Form.Group>
                <Form.Group className={styles.pass}>
                    <input type="password" placeholder="Пароль" className={styles.input} onChange={handleChange} value={formData.password} />
                </Form.Group>
                <button variant="primary" type="submit" className={styles.btn}>
                    Вход
                </button>
            </Form>
        </div >
    )
}

export default inject(({ authData }) => {
    const { isLoggedIn, logIn } = authData

    return {
        isLoggedIn,
        logIn
    }
})(observer(FormLogin))