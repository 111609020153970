import {
    makeAutoObservable,
    runInAction
} from "mobx"
import * as constants from '../utils/constants'

const {
    FREE_FALL,
    SOS,
    SHARP_BLOW,
    PULSE
} = constants

export default class EventsStore {
    events = []
    alerts = []
    dangerStatus = false
    emergencyMessage = null
    dangerEvent = null

    constructor(instance) {
        this.rootStore = instance
        makeAutoObservable(this, {}, {
            deep: true
        })
    }

    setEvents = (event) => {
        const ev = JSON.parse(event)

        if (ev.type !== "info") {
            const orgStore = this.rootStore.OrgStore

            !orgStore.workers.length && orgStore.getWorkers()

            ev.type === "alert_messages" && this.setDangerStatus(ev.data)

            const modifiedEvents = this.modifyEvents(ev.type, ev.data)

            runInAction(() => this.events = [...modifiedEvents, ...this.events])
        } else {
            console.log(ev)
        }
    }

    modifyEvents = (type, data) => {
        const isData = data && data.length

        if (type === "access_requests") {
            const modifiedEvents = isData
                ? data.map(ev => {
                    ev.worker = this.rootStore.OrgStore.workers.find(({ id }) => id === ev.userId)
                    return ev
                })
                : []
            return modifiedEvents
        }
        if (type === "alert_messages") {
            const modifiedEvents = isData
                ? data.map(ev => {
                    ev.worker = this.rootStore.OrgStore.workers.find(({ deviceId }) => deviceId === ev.deviceId)
                    return ev
                })
                : []
            return modifiedEvents
        }
    }

    setDangerStatus = (data) => {
        for (let event of data) {
            if (event && event.emergencyType) {
                this.dangerStatus = true
                this.dangerEvent = event

                switch (event.emergencyType) {
                    case 'ALERT_FREE_FALL (свободное падение)':
                        this.emergencyMessage = FREE_FALL
                        break;
                    case 'ALERT_SOS (тревожная кнопка)':
                        this.emergencyMessage = SOS
                        break;
                    case 'ALERT_SHARP_BLOW (резкий удар)':
                        this.emergencyMessage = SHARP_BLOW
                        break;
                    case 'ALERT_PULSE (пульс)':
                        this.emergencyMessage = PULSE
                        break;
                    default:
                        this.emergencyMessage = ''
                        break;
                }
            }
        }
    }
}