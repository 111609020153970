import React, { useEffect, useState } from "react";
import "chartjs-adapter-date-fns";
import "./chart.scss";
import Button from "../Button";

import {
  add,
  startOfMinute,
  addMinutes,
  subMinutes,
  format,
  startOfHour,
} from "date-fns";
import zoomPlugin from "chartjs-plugin-zoom";
import { Bar, Scatter } from "react-chartjs-2";
import {
  colorize,
  getQueryDate,
  floor10,
  ceil10,
  getRandomInt,
} from "../../helpers/helpers";
import * as constants from "../../utils/constants";

import { Chart, registerables } from "chart.js";

import Api from "../../utils/api/api";
const api = new Api();

Chart.register(...registerables);
Chart.register(zoomPlugin);

const { hoursStart, totalHoursADay, ALERT_FREE_FALL, ALERT_SOS, ALERT_PULSE } =
  constants;

// Плагин для отображения названия события на таймлайне
const scatterChartLabels = {
  id: "scatterChartLabels",
  afterDatasetsDraw(chart, args, options) {
    const { ctx } = chart;
    ctx.save();

    ctx.font = "10px sans-serif";

    for (let x = 0; x < chart.config.data.datasets?.length; x++) {
      for (let i = 0; i < chart.config.data.datasets[x].data.length; i++) {
        let textWidth = ctx.measureText(
          chart.config.data.datasets[x].data[i]?.label
        ).width;
        ctx.fillStyle = chart.config.data.datasets[x].data[i]?.isCritical
          ? "#EE0B0B"
          : "#C4C4C4";
        const xScale = chart.getDatasetMeta(x).data[i]?.x - textWidth / 2;
        const yScale = chart.getDatasetMeta(x).data[i]?.y + 17;
        ctx.fillText(
          chart.config.data.datasets[x].data[i]?.label,
          xScale,
          yScale
        );
      }
    }

    ctx.restore();
  },
};

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.classList.add("tooltip");

    tooltipEl.style.opacity = 1;

    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};

const externalTooltipHandler = (ctx) => {
  const { chart, tooltip } = ctx;
  const pointEvents = tooltip.dataPoints?.[0].raw.info;

  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const list = document.createElement("ul");
    list.classList.add("tooltip__list");
    pointEvents.forEach((e) => {
      const li = document.createElement("li");
      li.classList.add("tooltip__item", "d-flex", "justify-content-between");

      li.innerHTML += `<span>${
        e.emergencyName.name
      }</span><span class="fw-bold">${format(
        new Date(e.receivedAt),
        "HH:mm:ss"
      )}</span>`;

      list.appendChild(li);
    });

    const listRoot = tooltipEl;

    while (listRoot.firstChild) {
      listRoot.firstChild.remove();
    }

    listRoot.appendChild(list);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
};

const ChartContainer = ({
  worker,
  fromDT,
  handleIsTriggersEnabled,
  handleSteps,
  totalEvents,
}) => {
  const [timeAggregate, setTimeAggregate] = useState(15);

  // Определяем 06:00 выбранного дня
  const startOfShift = add(new Date(fromDT), {
    hours: hoursStart,
  });

  // Определяем 00:00 выбранного дня
  const endOfShift = add(new Date(startOfShift), {
    hours: totalHoursADay,
  });

  let defaultData = {
    labels: [startOfShift],
    accel: [],
    defaultAccel: [],
    pulse: [],
  };

  const addDefaultData = (obj, date) => {
    if (date >= endOfShift) return;
    const nextDate = addMinutes(date, timeAggregate);
    nextDate !== endOfShift && obj.labels.push(nextDate);
    obj.accel.push(getRandomInt(1, 10));
    obj.defaultAccel.push(10);
    obj.pulse.push([getRandomInt(50, 90), getRandomInt(50, 90)]);
    addDefaultData(obj, nextDate);
    return obj;
  };

  addDefaultData(defaultData, defaultData.labels[0]);

  const [labels, setLabels] = useState(defaultData.labels);
  const [pulse, setPulse] = useState(defaultData.pulse);
  const [accel, setAccel] = useState(defaultData.accel);
  const defaultAccel = defaultData.defaultAccel;

  const [timelineTriggers, setTimelineTriggers] = useState([]);

  const [pulseColor, setPulseColor] = useState({
    pulse: "rgba(218, 218, 218, 1)",
    ticks: "rgba(218, 218, 218, 1)",
    lines: "rgba(218, 218, 218, 1)",
  });

  const [pulseLines, setPulseLines] = useState({
    minPulseLine: 50,
    maxPulseLine: 90,
  });

  const [activityColors, setActivityColors] = useState(
    "rgba(218, 218, 218, 1)"
  );

  const [buttonState, setButtonState] = useState({
    accel: {
      className: "button-eye button-eye_hide border-0",
      action: "hide",
      isDisabled: false,
    },
    pulse: {
      className: "button-eye button-eye_hide border-0",
      action: "hide",
      isDisabled: false,
    },
  });

  const [isDataEnabled, setisDataEnabled] = useState({
    accel: false,
    pulse: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const getDeviceData = async (date, w) => {
    if (date && w) {
      setIsLoading(true);

      const data =
        w?.deviceId &&
        (await api.getData(
          `analytics/planned_data/data_any?fromDT=${date}&deviceId=${w.deviceId}&timeAggregate=${timeAggregate}&offset=0&limit=100`
        ));

      const filteredData =
        data && data.length
          ? data
              ?.filter(
                ({ registeredAt }) => getQueryDate(registeredAt) === fromDT
              )
              .sort(
                (a, b) => new Date(a.registeredAt) - new Date(b.registeredAt)
              )
              .map((d) => {
                return {
                  registeredAt: startOfMinute(new Date(d.registeredAt)),
                  pulsePoints: d.extra?.["pulse_point"]
                    ? Object.values(d.extra["pulse_point"]).sort(
                        (a, b) => a - b
                      )
                    : [0, 0],
                  accelPoint: d.accelPoint,
                  day: getQueryDate(d.registeredAt),
                  steps: d.stepsPoint,
                };
              })
          : [];

      if (!filteredData.length) {
        setisDataEnabled({
          accel: false,
          pulse: false,
        });

        setButtonState({
          ...buttonState,
          accel: {
            className: "button-eye button-eye_hide border-0",
            isDisabled: true,
            action: "hide",
          },
          pulse: {
            className: "button-eye button-eye_hide border-0",
            isDisabled: true,
            action: "hide",
          },
        });

        setIsLoading(false);
      } else {
        // Прокидываем кол-во шагов на компонент вверх, чтобы отображать в модальном окне. В карточке на странице Сотрудники они не отображаются
        handleSteps && handleSteps(filteredData[filteredData.length - 1].steps);

        const chartsData = {
          labels: filteredData.map((d) => d.registeredAt),
          pulse: filteredData.map((d) =>
            d.pulsePoints ? d.pulsePoints : [0, 0]
          ),
          accel: filteredData.map((d) => (d.accelPoint ? d.accelPoint : 0)),
        };

        // Добавляем данные в начало с 06:00 (начало рабочего дня) до начала смены
        const addDataToBegin = (obj, date) => {
          if (!obj || !date) return;
          if (date <= startOfShift) return;
          const previousDate = subMinutes(date, timeAggregate);

          obj?.labels?.unshift(previousDate);
          obj?.pulse?.unshift([0, 0]);
          obj.accel?.unshift(0);

          addDataToBegin(obj, previousDate);
          return obj;
        };

        // Добавляем данные с последнего времени смены до конца дня 00:00
        const addDataToEnd = (obj, date) => {
          if (!obj || !date) return;
          if (date >= endOfShift) return;
          const nextDate = addMinutes(date, timeAggregate);

          obj?.labels?.push(nextDate);
          obj?.pulse?.push([0, 0]);
          obj.accel?.push(0);

          addDataToEnd(obj, nextDate);
          return obj;
        };

        // Обогащаем объект данных дополнительными данными для отображения графика с 06:00 до 00:00
        addDataToBegin(chartsData, chartsData?.labels?.[0]);
        addDataToEnd(
          chartsData,
          chartsData?.labels?.[chartsData?.labels?.length - 1]
        );

        const isPulse =
          chartsData.pulse?.flat().some((val) => val > 0) === undefined
            ? false
            : chartsData.pulse?.flat().some((val) => val > 0);
        // Наличие активности изменено на true, т.к. она есть всегда, просто может быть равна 0
        const isAccel = filteredData.length ? true : false;
        // chartsData.accel?.some((val) => val > 0) === undefined
        //   ? false
        //   : chartsData.accel?.some((val) => val > 0);

        chartsData.pulse && isPulse && setPulse(chartsData.pulse);
        chartsData.accel && isAccel && setAccel(chartsData.accel);

        const pulseData = filteredData.map((d) => d.pulsePoints).flat();

        const minPulseLine = isPulse
          ? floor10(Math.min(...pulseData), 1)
          : pulseLines.minPulseLine;
        const maxPulseLine = isPulse
          ? ceil10(Math.max(...pulseData), 1)
          : pulseLines.maxPulseLine;

        setPulseLines({
          minPulseLine: minPulseLine,
          maxPulseLine: maxPulseLine,
        });

        isAccel && setActivityColors(colorize(chartsData?.accel));

        chartsData.pulse &&
          isPulse &&
          setPulseColor({
            pulse: "rgba(255, 68, 68, 1)",
            ticks: "rgba(255, 68, 68, 0.7)",
            lines: "rgba(255, 68, 68, 0.41)",
          });

        setButtonState({
          ...buttonState,
          accel: {
            className: !isAccel
              ? "button-eye button-eye_hide border-0"
              : "button-eye button-eye_show border-0",
            isDisabled: !isAccel,
            action: !isAccel ? "" : "hide",
          },
          pulse: {
            ...pulse,
            className: !isPulse
              ? "button-eye button-eye_hide border-0"
              : "button-eye button-eye_show border-0",
            isDisabled: !isPulse,
            action: !isPulse ? "" : "hide",
          },
        });

        setisDataEnabled({
          accel: isAccel,
          pulse: isPulse,
        });

        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    // Данные в графиках акивности и пульса обновляются каждые 60 секунд, если открыта сегодняшняя дата
    getDeviceData(fromDT, worker);

    const today = getQueryDate(new Date());

    const interval = setInterval(() => {
      if (fromDT === today) {
        getDeviceData(fromDT, worker);
      }
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [fromDT, worker]);

  // Поднимаем стейт наличия данных для отображения/скрытия триггеров
  useEffect(() => {
    handleIsTriggersEnabled(isDataEnabled);
  });

  useEffect(() => {
    if (totalEvents?.length) {
      const formattedEvents = totalEvents.map((e) => {
        return {
          ...e,
          startOfHour: startOfHour(new Date(e.receivedAt)).toString(),
        };
      });

      const hours = Array.from(
        new Set(formattedEvents.map((e) => e.startOfHour))
      );

      const groupedHours = hours.map((key) => {
        return {
          [key]: formattedEvents.filter((e) => e.startOfHour === key),
        };
      });

      let triggers = [];

      groupedHours.forEach((hour) => {
        Object.keys(hour).forEach((key) => {
          const timelineObj = {
            x: new Date(key),
            y: 20,
            info: hour[key],
            label: hour[key].length,
            isCritical: hour[key].some(
              ({ emergencyType }) =>
                emergencyType === ALERT_FREE_FALL ||
                emergencyType === ALERT_PULSE ||
                emergencyType === ALERT_SOS
            ),
          };

          triggers.push(timelineObj);
        });
      });

      setTimelineTriggers(triggers);
    }
  }, [totalEvents]);

  useEffect(() => {
    // Когда контейнер с графиками размонтируется, возвращаем нулевое значение шагов
    return () => {
      handleSteps && handleSteps(0);
    };
  }, []);

  const activityChartOptions = {
    layout: {
      padding: {
        left: 24,
      },
    },
    responsive: true,
    datasets: {
      bar: {
        maxBarThickness: 20,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      // zoom: {
      //   zoom: {
      //     wheel: {
      //       enabled: isDataEnabled.accel,
      //     },
      //     pinch: {
      //       enabled: isDataEnabled.accel,
      //     },
      //     mode: "x",
      //     onZoom: function (instance) {
      //       const charts = Object.values(Chart.instances);
      //       let activityChart = instance.chart;

      //       let pulseChartID = activityChart.id + 1;

      //       let pulseChart = charts.find((ch) => ch.id === pulseChartID);

      //       pulseChart.options.scales.x.min =
      //         activityChart.options.scales.x.min;
      //       pulseChart.options.scales.x.max =
      //         activityChart.options.scales.x.max;
      //       pulseChart.update();
      //     },
      //   },
      // },
      tooltip: {
        enabled: isDataEnabled.accel,
        callbacks: {
          title: function (ctx) {
            let title =
              format(new Date(ctx[0].parsed.x), "С HH:mm") +
              " " +
              format(new Date(ctx[0].parsed.x + 15 * 60 * 1000), "по HH:mm");
            return title;
          },
          label: function (ctx) {
            let label = "Активность: " + ctx.parsed.y;
            return label;
          },
        },
      },
    },
    elements: {
      bar: {
        backgroundColor: activityColors,
        borderRadius: 5,
        borderSkipped: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        position: "top",
        stacked: true,
        type: "time",
        time: {
          displayFormats: {
            hour: "HH:mm",
          },
          unit: "hour",
        },
        ticks: {
          color: "black",
        },
      },
      y: {
        display: false,
        min: 0,
        max: 10,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
  };

  const pulseChartOptions = {
    responsive: true,
    datasets: {
      bar: {
        maxBarThickness: 4,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      // zoom: {
      //   zoom: {
      //     wheel: {
      //       enabled: isDataEnabled.pulse,
      //     },
      //     pinch: {
      //       enabled: isDataEnabled.pulse,
      //     },
      //     mode: "x",
      //     onZoom: function (instance) {
      //       const charts = Object.values(Chart.instances);

      //       let pulseChart = instance.chart;

      //       let activityChart = charts.find(
      //         (ch) => ch.id === pulseChart.id - 1
      //       );

      //       let triggersChart = charts.find(
      //         (ch) => ch.id === pulseChart.id + 1
      //       );

      //       activityChart.options.scales.x.min =
      //         pulseChart.options.scales.x.min;
      //       activityChart.options.scales.x.max =
      //         pulseChart.options.scales.x.max;
      //       activityChart.update();

      //       triggersChart.options.scales.x.min =
      //         pulseChart.options.scales.x.min;
      //       triggersChart.options.scales.x.max =
      //         pulseChart.options.scales.x.max;
      //       triggersChart.update();
      //     },
      //   },
      // },
      tooltip: {
        enabled: isDataEnabled.pulse,
        callbacks: {
          title: function (ctx) {
            let title =
              format(new Date(ctx[0].parsed.x), "С HH:mm") +
              " " +
              format(new Date(ctx[0].parsed.x + 15 * 60 * 1000), "по HH:mm");
            return title;
          },
          label: function (ctx) {
            let label = [
              `Min: ${ctx.parsed._custom.barStart} Max: ${ctx.parsed._custom.barEnd}`,
            ];
            return label;
          },
        },
      },
    },
    elements: {
      bar: {
        backgroundColor: pulseColor.pulse,
        borderRadius: 10,
        borderSkipped: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        min: pulseLines?.minPulseLine,
        max: pulseLines?.maxPulseLine,
        grid: {
          display: true,
          drawBorder: false,
          color: pulseColor.lines,
          tickLength: 0,
        },
        ticks: {
          callback: function (value) {
            return value === pulseLines?.minPulseLine ||
              value === pulseLines?.maxPulseLine ||
              value ===
                (pulseLines?.maxPulseLine + pulseLines?.minPulseLine) / 2
              ? value
              : null;
          },
          color: pulseColor.ticks,
          stepSize: 5,
        },
      },
      x: {
        display: false,
        type: "time",
        time: {
          displayFormats: {
            hour: "HH:mm",
          },
          unit: "hour",
        },
      },
    },
  };

  const pulseChartData = {
    labels,
    datasets: [
      {
        data: pulse,
      },
    ],
  };

  const activityChartData = {
    labels,
    datasets: [
      {
        id: 1,
        label: "Активность",
        data: accel,
      },
      {
        id: 2,
        label: "",
        data: defaultAccel,
        backgroundColor: "white",
        borderWidth: 1.5,
        borderColor: "rgba(218, 218, 218, 1)",
      },
    ],
  };

  const triggersChartData = {
    labels,
    datasets: [
      {
        data: (isDataEnabled.accel || isDataEnabled.pulse) && timelineTriggers,
        backgroundColor: function (ctx) {
          return ctx?.dataset?.data?.map((d) => {
            if (d.isCritical) {
              return "#EE0B0B";
            } else {
              return "#C4C4C4";
            }
          });
        },
      },
    ],
  };

  const triggersChartOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 24,
      },
    },
    responsive: true,
    scales: {
      x: {
        display: false,
        type: "time",
        time: {
          displayFormats: {
            hour: "HH:mm",
          },
          unit: "hour",
        },
      },
      y: {
        beginAtZero: true,
        display: false,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler,
      },
      // tooltip: {
      //   callbacks: {
      //     label: function (ctx) {
      //       const pointEvents = ctx.raw.info;
      //       // console.log(pointEvents);

      //       let list = pointEvents.map(
      //         (e) =>
      //           `${e.emergencyName.name} ${format(
      //             new Date(e.receivedAt),
      //             "HH:mm:ss"
      //           )}`
      //       );

      //       return list;
      //     },
      //   },
      // },
      // zoom: {
      //   zoom: {
      //     wheel: {
      //       enabled: true,
      //     },
      //     pinch: {
      //       enabled: true,
      //     },
      //     mode: "x",
      //     onZoom: function (instance) {
      //       const charts = Object.values(Chart.instances);

      //       let triggersChart = instance.chart;

      //       let activityChart = charts.find(
      //         (ch) => ch.id === triggersChart.id - 2
      //       );
      //       let pulseChart = charts.find(
      //         (ch) => ch.id === triggersChart.id - 1
      //       );

      //       activityChart.options.scales.x.min =
      //         triggersChart.options.scales.x.min;
      //       activityChart.options.scales.x.max =
      //         triggersChart.options.scales.x.max;
      //       activityChart.update();

      //       pulseChart.options.scales.x.min =
      //         triggersChart.options.scales.x.min;
      //       pulseChart.options.scales.x.max =
      //         triggersChart.options.scales.x.max;
      //       pulseChart.update();
      //     },
      //   },
      // },
    },
    elements: {
      point: {
        radius: 7,
        hoverRadius: 5,
        borderWidth: 0,
        hoverBorderWidth: 0,
      },
    },
  };

  const handleToggleData = (e) => {
    const name = e.target.dataset.name;
    const action = e.target.dataset.action;

    setButtonState({
      ...buttonState,
      [name]: {
        ...[name],
        className: `button-eye button-eye${
          action === "hide" ? "_hide" : "_show"
        } border-0`,
        action: action === "show" ? "hide" : "show",
      },
    });

    if (action === "hide") {
      if (name === "accel") {
        const colors = new Array(accel.length).fill("rgba(196, 196, 196, 1)");
        setActivityColors(colors);
      }
      if (name === "pulse") {
        setPulseColor({
          pulse: "rgba(196, 196, 196, 1)",
          ticks: "rgba(196, 196, 196, 1)",
          lines: "rgba(196, 196, 196, 1)",
        });
      }
    }
    if (action === "show") {
      if (name === "accel") {
        const colors = colorize(accel);
        setActivityColors(colors);
      }
      if (name === "pulse") {
        setPulseColor({
          pulse: "rgba(255, 68, 68, 1)",
          ticks: "rgba(255, 68, 68, 1)",
          lines: "rgba(255, 68, 68, 0.41)",
        });
      }
    }
  };

  return (
    <div className="position-relative">
      {isLoading ? (
        <div className="position-absolute w-100 h-100 info-message">
          <div className="d-flex justify-content-center align-items-center">
            Идет загрузка...
          </div>
        </div>
      ) : (
        !isDataEnabled.accel &&
        !isDataEnabled.pulse && (
          <div className="position-absolute w-100 h-100 info-message">
            <div className="d-flex justify-content-center align-items-center">
              К сожалению, на текущую дату нет данных...
            </div>
          </div>
        )
      )}
      <div className="chart-container position-relative">
        <Button
          className={buttonState.accel.className}
          action={buttonState.accel.action}
          name="accel"
          onClick={(e) => handleToggleData(e)}
          isDisabled={buttonState.accel.isDisabled}
        />
        {
          <Bar
            options={activityChartOptions}
            data={activityChartData}
            height={70}
          />
        }
      </div>
      <div className="chart-container position-relative">
        <Button
          className={buttonState.pulse.className}
          action={buttonState.pulse.action}
          name="pulse"
          onClick={(e) => handleToggleData(e)}
          isDisabled={buttonState.pulse.isDisabled}
        />
        {<Bar options={pulseChartOptions} data={pulseChartData} height={100} />}
      </div>
      <div className="chart-container">
        <Scatter
          options={triggersChartOptions}
          data={triggersChartData}
          height={27.5}
          plugins={[scatterChartLabels]}
        />
      </div>
    </div>
  );
};

export default ChartContainer;
