/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from "react";
import { useWorkerEvents } from "../../hooks/useWorkerEvents";
import { Modal } from "react-bootstrap";
import { format } from "date-fns";
import {
  getQueryDate,
  getWord
} from "../../helpers/helpers";
import Button from "../Button";
import ChartContainer from "../ChartContainer";
import PersonContainer from "./PersonContainer";

import stepsImg from "../../assets/images/steps.svg";
import watch from "../../assets/images/watch.png";
import arrow from "../../assets/images/arrowBattery.svg";
import nfc from "../../assets/images/important.svg";

import Api from "../../utils/api/api";
const api = new Api()

const PersonModal = ({
  showModal,
  handleCloseModal,
  queryParams
}) => {
  const fullname =
    getWord(queryParams?.worker?.name) +
    " " +
    getWord(queryParams?.worker?.surname);
  const position = getWord(queryParams?.worker?.position);

  const [session, setSession] = useState({
    dateBegin: "",
    dateEnd: "",
    isActive: false,
    duration: "",
  });

  const [steps, setSteps] = useState(0);
  const handleSteps = (val) => {
    setSteps(val);
  };

  // Используем отдельно вынесенный кастомный хук для получения событий для нижнего таймлайна
  const data = useWorkerEvents(
    queryParams?.fromDT,
    queryParams?.worker.deviceId
  );

  // Деструктуриуем массив полученных данных
  const [workerEvents, handleIsTriggersEnabled, , , totalEvents] = data;

  const [showNFC, setShowNFC] = useState(false);

  const handleShowNFC = () => {
    setShowNFC(!showNFC);
  };

  const [deviceData, setDeviceData] = useState({
    device: null,
    config: null
  })

  const getDeviceData = async (id, device) => {
    try { 
      const data = await api.getData(`device/configuration?deviceId=${id}`)
      
      data.created = format(new Date(data.createdAt), "dd.MM.yyyy")

      setDeviceData({
        device: device,
        config: data
      })
    } catch(error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (queryParams.worker && queryParams.fromDT) {
      const thisDaySession = queryParams.worker.sessionsHistory.find(
        ({ dateBegin }) =>
          getQueryDate(new Date(dateBegin)) === queryParams.fromDT
      );

      if (thisDaySession) {
        setSession(thisDaySession)
      }

      const device = queryParams.worker.device

      const eui = device.eui

      getDeviceData(eui, device)
    }
  }, [queryParams]);

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered animation={true}>
      <div className="d-flex flex-wrap justify-content-start align-items-stretch border-0 modal-header">
        <PersonContainer
          fullname={fullname}
          position={position}
          session={session}
        />
        <div className="info-block d-flex flex-wrap">
          <div className="info-block__item d-flex align-items-center justify-content-center">
            <p className="fw-bold">
              {session && session.duration ? session.duration : "Нет данных"}
            </p>
          </div>
          <div className="d-flex flex-column text-center time-block">
            <div className="time-block__item">
              <p>
                {session.start ? "Начал" + " " + session.start : "Нет данных"}
              </p>
            </div>
            <div className="time-block__item">
              <p>
                {session.end ? "Закончил" + " " + session.end : "Нет данных"}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center info-block__item info-block__item_gray">
            <img className="item-img item-img_steps" src={stepsImg} alt="" />
            <p>{steps} шагов</p>
          </div>
        </div>
        <div className="position-absolute button-close-container">
          <Button
            text="закрыть"
            onClick={handleCloseModal}
            className="button-close border-0"
          />
        </div>
      </div>
      <div className="card__body">
        <ChartContainer
          worker={queryParams?.worker}
          fromDT={queryParams?.fromDT}
          handleIsTriggersEnabled={handleIsTriggersEnabled}
          workerEvents={workerEvents}
          handleSteps={handleSteps}
          totalEvents={totalEvents}
        />
      </div>
      <div className="d-flex flex-wrap blocks-container">
        <div className="d-flex flex-column block-container justify-content-between">
          <h4 className="title block-container__title">Нарушения и события</h4>
          <div
            className={`block ${
              !totalEvents.length
                ? "d-flex justify-content-center w-100 align-items-center"
                : ""
            }`}
          >
            {totalEvents && totalEvents.length ? (
              <ul className="events-list list">
                {totalEvents?.map((ev) => {
                  return (
                    <li
                      key={ev.id}
                      className={`events-list__item list__item d-flex w-100 align-items-center event ${
                        ev.emergencyName.critical && "event_critical"
                      }`}
                    >
                      <p className="event__icon">icon</p>
                      <p className="event__name flex-grow-1">
                        {ev.emergencyName.name}
                      </p>
                      <p className="event__time">{ev.time}</p>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="info info_no-data">Нет данных</p>
            )}
          </div>
        </div>
        <div className="d-flex flex-column justify-content-between block-container position-relative">
          <h4 className="title block-container__title">Носимое устройство</h4>
          <div className="block device-block d-flex w-100">
            <div className="w-50 text-center device">
              <div>
                <img src={watch} alt="device" />
              </div>
              <div className="device__info">
                <p className="fw-bold">{deviceData?.device?.name}</p>
                <p className="info info_grey">S/N {deviceData?.device?.eui}</p>
                <p className="info info_grey">{deviceData?.config?.created}</p>
              </div>
            </div>
            <div className="w-50">
              <ul className="device-list">
                <li className="device-list__item w-100">
                  <p>Конфигурация:</p>
                  <p className="fw-bold">{deviceData?.config?.title ? getWord(deviceData?.config?.title) : ""}</p>
                </li>
                <li className="device-list__item w-100">
                  <p>Разряд батареи:</p>
                  <p>
                    <span className="battery battery_start fw-bold">94% </span>
                    <span className="battery battery_arrow">
                      <img src={arrow} alt="" />
                    </span>
                    <span className="battery battery_last fw-bold"> 53% </span>
                    {session.duration ? "за " + session.duration : ""}
                  </p>
                </li>
                <li className="device-list__item w-100">
                  <p>Циклов использования / заряда</p>
                  <p className="fw-bold">30 смен / 5 зарядов</p>
                </li>
                <li className="device-list__item w-100">
                  <p>Остаточный ресурс до обслуживания</p>
                  <p className="fw-bold">333 смен / 111 зарядов</p>
                </li>
                <li className="device-list__item w-100 d-flex justify-content-between">
                  <div>
                    <p>Время экрана</p>
                    <p className="fw-bold">35 минут</p>
                  </div>
                  <div>
                    <div>Использование NFC</div>
                    <div className="fw-bold">
                      4 раза
                      <img
                        className="nfc-img"
                        src={nfc}
                        alt="nfc"
                        onMouseOver={handleShowNFC}
                        onMouseOut={handleShowNFC}
                      />
                      <div
                        className={`position-absolute ${
                          showNFC ? "nfc nfc_show" : "nfc nfc_hide"
                        }`}
                      >
                        <ul className="events-list events-list_nfc fw-normal">
                          <li className="d-flex justify-content-between w-100">
                            <div className="fw-bold">Старт смены</div>
                            <div>
                              {session.start ? session.start : "Нет данных"}
                            </div>
                          </li>
                          <li className="d-flex justify-content-between w-100">
                            <div className="fw-bold">
                              Подтверждение личности
                            </div>
                            <div>HH:mm</div>
                          </li>
                          <li className="d-flex justify-content-between w-100">
                            <div className="fw-bold">
                              Здесь будут еще какие-то данные
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="device-list__item w-100">
                  <p>Датчик вандальных действий</p>
                  <p className="fw-bold">Нет</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-end block-container">
          <div className="block d-flex align-items-center justify-content-center">
            <p className="info info_grey">Блок в разработке</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PersonModal;
