import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import Button from "../Button";
import "./shift.scss";
import photo from "../../assets/images/photo.svg";
import { getWord, getHours, getTime, timeDiff } from "../../helpers/helpers";

const ConfirmationCard = ({
  id,
  worker,
  isActive,
  isApproved,
  start,
  end,
  finishSession,
  approveSession,
  disapproveSession,
}) => {
  const fullName = worker
    ? getWord(worker.name) + " " + getWord(worker.surname)
    : "";

  const workingHours = start && new Date() - new Date(start);
  const startedAt = start ? getTime(new Date(start)) : getTime(new Date());

  const changeData = (e, id) => {
    const action = e.target.dataset.action;
    console.log(action);

    action === "finish" && finishSession(action, id);
    action === "approve" && approveSession(action, id);
    action === "disapprove" && disapproveSession(action, id);
  };

  return (
    <div
      className={`d-flex flex-wrap align-items-center justify-content-between position-relative base-card ${
        !isActive && "base-card_confirm"
      }`}
    >
      <div className="d-flex align-items-center info-box">
        <div
          className={`${
            !isActive ? "position-absolute img-container" : "img-container"
          }`}
        >
          <img
            className={`${!isActive ? "img img_big" : "img"}`}
            src={photo}
            alt="avatar"
          />
        </div>
        <div className="info">
          <p>
            <span className="fw-bold">{fullName}</span>
            {isActive
              ? " вышел на смену в " + startedAt
              : " хочет выйти на смену"}
          </p>
          <p className="info info_lisenced">Все разрешения в порядке</p>
          {/* {isApproved ? (
            <p className="info info_lisenced">Все разрешения в порядке</p>
          ) : (
            <p className="info info_no-lisenced">
              Отсутствует разрешение на работу
            </p>
          )} */}
          {/* {
                        (isActive && !end && <p>Работает {workingHours} часов с {startedAt}</p>)
                    } */}
        </div>
      </div>
      <div className="buttons-container">
        {isActive ? (
          <Button
            text="Отозвать"
            className="button button_finish fw-bold"
            onClick={(e) => changeData(e, id)}
            action="finish"
          />
        ) : (
          <>
            <Button
              text="Запретить"
              className="button button_disapprove"
              onClick={(e) => changeData(e, id)}
              action="disapprove"
            />
            <Button
              text="Подтвердить"
              className="button button_approve fw-bold"
              onClick={(e) => changeData(e, id)}
              action="approve"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default inject(({ shiftsStore }) => {
  const { finishSession, approveSession, disapproveSession } = shiftsStore;

  return {
    finishSession,
    approveSession,
    disapproveSession,
  };
})(observer(ConfirmationCard));
