import React from "react";
import './alert.scss'

const Alert = ({
    eventData
}) => {
    return (
        <div className="alert-container">
            <div className="alert-container__type">
                <span className="alert-container__arrow">{eventData.type}</span>
            </div>
            <div>
                <p>{eventData.worker}</p>
                <p>Объект: {eventData.objectName}</p>
            </div>
        </div>
    )
}

export default Alert;