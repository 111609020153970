import React, { useEffect, useState } from "react";
import './events.scss';

import { getTime, getDayOfMonth, getStartOfDay } from "../../helpers/helpers";
import { compareAsc } from 'date-fns'

const Event = ({
    event
}) => {
    const [eventData, setEventData] = useState(null)

    useEffect(() => {
        const compareDatesValue = compareAsc(getStartOfDay(event.receivedAt), getStartOfDay(new Date()))

        const formattedEvent = {
            ...event,
            time: getTime(event.receivedAt) || 'Н/Д',
            // Дата, если не сегодня
            date: event.receivedAt && (compareDatesValue === -1 || compareDatesValue === 1)
                ? getDayOfMonth(event.receivedAt)
                : ''
        }

        setEventData(formattedEvent)
    }, [event])

    return (
        <div className={`event-container event-container_${event.eventType}`}>
            <div className={`event-container__icon event-container__icon_${event.eventType}`}>
                icon
            </div>
            <div className='event-container__info'>
                <p>
                    <span className='fw-bold'>{eventData?.time}</span> {eventData?.date}
                </p>
                <p>
                    <span className='fw-bold'>{eventData?.emergencyType === 'ALERT_PULSE (пульс)' && 'Высокий пульс'}</span> сопровождающий текст
                </p>
                <p>
                    <span className='fw-bold'>{eventData?.worker.surname + " " + eventData?.worker.name}</span>
                </p>
            </div>
        </div>
    )
}

export default Event