import { makeAutoObservable, runInAction, toJS } from "mobx";
import Api from '../utils/api/api';
import { getMonth, getWord, getQueryDate, getUniqueObjects, getTime, lateDuration, sessionDuration } from '../helpers/helpers'
import { differenceInMinutes, format, getDaysInMonth, intervalToDuration } from 'date-fns'

const api = new Api();

const getMonthsDays = (day) => {
    // Первые 3 буквы названия месяца
    const thisMonth = getMonth(day).slice(0, 3);

    const thisMonthLength = getDaysInMonth(day);

    const days = [];
    for (let i = 1; i <= thisMonthLength; i++) {
        i < 10
            ? days.push("0" + i + " " + thisMonth)
            : days.push("" + i + " " + thisMonth);
    }

    return days;
};

const setSessionProperties = (session) => {
    if (session) {
        const { workStart, workEnd } = session.activity
        const date = session.date
        const schedule = session.schedule

        const scheduleStart = new Date(date + " " + schedule.workStart)
        const scheduleEnd = new Date(date + " " + schedule.workEnd)

        const sessionObj = {
            deviceId: session.deviceId,
            start: workStart ? getTime(workStart) : "",
            end: workEnd ? getTime(workEnd) : "",
            isActive: false,
            dateBegin: workStart,
            dateEnd: workEnd,
            hasAlerts: session.hasAlerts,
            hasCriticalAlerts: session.hasCriticalAlerts,

            schedule: {
                start: scheduleStart,
                end: scheduleEnd,
                isLate: new Date(workStart) > new Date(date + " " + schedule.workStart) ? true : false,
                lateTime: new Date(workStart) > new Date(date + " " + schedule.workStart)
                    ? differenceInMinutes(new Date(workStart), scheduleStart) <= 8 * 60
                        ? lateDuration(date + " " + schedule.workStart, workStart)
                        : "8 часов"
                    : ""
            }
        }

        return sessionObj
    }
}

export default class OrgStore {
    // Неизменяемые данные
    objects = []
    profiles = []
    positions = []
    datesData = []
    months = []
    monthsData = []
    workers = []

    // Вариативные данные
    isLoading = false
    error = null

    constructor(instance) {
        this.rootStore = instance
        makeAutoObservable(this, {}, { deep: true });
    }

    getWorkers = async () => {
        try {
            this.isLoading = true

            const data = await api.getData("org/profile")

            this.setProfiles(data)

            this.isLoading = false
        } catch (error) {
            runInAction(() => {
                this.error = error
                this.isLoading = false
                console.log(error)
            })
        } finally {
            runInAction(() => {
                this.isLoading = false
            })
        }
    }

    setProfiles = data => runInAction(() => this.workers = data)

    initConstData = async () => {
        try {
            this.isLoading = true

            const datesWithDataUrl = 'analytics/all_data/dates?offset=0&limit=100'

            const constData = await api.getDataAll([
                'org/orgobject',
                'org/profile',
                'org/position',
                'org/workshift',
                'org/workshiftsession',
                datesWithDataUrl,
                'device',
                'org/workshiftsession/history'
            ])

            runInAction(() => {
                this.setConstData(...constData)
            })

            this.isLoading = false
        } catch (error) {
            runInAction(() => {
                this.error = error
                this.isLoading = false
                console.log(error)
            })
        } finally {
            runInAction(() => {
                this.isLoading = false
            })
        }
    }

    setConstData = (objects, profiles, positions, workshifts, sessions, datesPlanned, devices, history) => {
        this.objects = objects
        this.positions = positions

        this.profiles = profiles.map(p => {
            let datesWithData = []

            for (let i = 0; i < datesPlanned.length; i++) {
                const isIncluded = datesPlanned[i].deviceIds.includes(p.deviceId)
                if (isIncluded) {
                    datesWithData.push(datesPlanned[i].date)
                }
            }

            return {
                ...p,
                workShiftId: workshifts.find(({ userId }) => userId === p.id).id,
                position: positions.find(({ id }) => id === p.positionId).title,
                device: devices.find(({ eui }) => eui === p.deviceId),
                datesWithData: datesWithData.sort((a, b) => new Date(b) - new Date(a))
            }
        })

        const formattedDates = datesPlanned.map(d => {
            const month = getWord(getMonth(new Date(d.date))).slice(0, -3)
            d.month = month
            return d
        }).map(d => {
            d.workers = []

            const ids = d.deviceIds || []
            for (let i = 0; i < ids.length; i++) {
                const worker = this.profiles.find(({ deviceId }) => deviceId === ids[i])
                d.workers.push(worker)
            }
            return d
        }).map(d => {
            return {
                ...d,
                workers: d.workers.map(w => {
                    return {
                        ...w,
                        session: w ? setSessionProperties(history.filter(({ date }) => date === d.date).find(({ deviceId }) => deviceId === w.deviceId)) : null
                    }
                })
            }
        })

        const groupedDates = formattedDates.map((d, i, array) => {
            return {
                [d.date]: array.find(({ date }) => date === d.date).workers
            }
        })

        // Удаляем даты, по которым нет работников
        for (let i = 0; i < groupedDates.length; i++) {
            const length = Object.values(groupedDates[i]).flat().length

            if (!length) {
                groupedDates.splice(i, 1)
            }
        }

        this.datesData = groupedDates

        const months = [...new Set(formattedDates.map(({ month }) => month))]

        this.monthsData = months.map(key => {
            const date = formattedDates.find(({ month }) => month === key).date
            const month = format(new Date(date), "yyyy-MM")

            let tablesDates = []
            for (let i = 1; i <= getDaysInMonth(new Date(date)); i++) {

                if (i < 10) {
                    tablesDates.push(month + '-' + '0' + i)
                } else {
                    tablesDates.push(month + '-' + i)
                }
            }

            const monthWorkers = datesPlanned.filter(d => getWord(getMonth(new Date(d.date))).slice(0, -3) === key).map(d => d.workers).flat().filter(Boolean)
            const workersArray = getUniqueObjects(monthWorkers.map(w => w))

            return {
                [key]: {
                    workers: workersArray && workersArray.length
                        ? workersArray.map(w => {
                            return {
                                ...w,
                                sessionsHistory: history.filter(({ deviceId }) => deviceId === w.deviceId)
                                    .map(s => {
                                        const session = setSessionProperties(s)

                                        const start = s.activity.workStart
                                        const end = s.activity.workEnd

                                        const index = Number(getQueryDate(new Date(start)).slice(-2)) - 1
                                        session.index = index
                                        session.date = getQueryDate(new Date(start))
                                        const interval =
                                            end && start
                                                ? intervalToDuration({
                                                    start: new Date(start),
                                                    end: new Date(end),
                                                })
                                                : null;

                                        const duration = interval
                                            ? (interval.hours < 10 ? "0" + interval.hours : interval.hours) +
                                            ":" +
                                            (interval.minutes < 10
                                                ? "0" + interval.minutes
                                                : interval.minutes)
                                            : "Н/Д";

                                        session.time = duration

                                        session.duration = sessionDuration(start, end)

                                        return session
                                    })
                            }
                        })
                        : [],
                    days: getMonthsDays(new Date(date)).map((d, i) => {
                        return {
                            day: d,
                            date: tablesDates[i]
                        }
                    })
                }

            }
        }
        )

        let monthsWithData = []
        // Из массива месяцев с данными удаляем те, в которых нет работников, и также создаем массив месяцев для выведения в селекте
        for (let i = 0; i < this.monthsData.length; i++) {
            const workers = Object.values((this.monthsData[i]))[0].workers

            if (!workers.length) {
                this.monthsData.splice(i, 1)
            } else {
                const month = Object.keys(this.monthsData[i])[0]
                monthsWithData.push(month)
            }
        }

        this.months = monthsWithData
    }
}