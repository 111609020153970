import stores from '../../stores/index'

const eventsStore = stores.eventsStore
const wsURL = 'ws://79.174.13.47:8001/ws'

const wsStart = wsURL => {
    const ws = new WebSocket(wsURL)

    // Инициализация коннекта
    ws.onopen = () => {
        console.log('ws connected')

        ws.readyState === 1 && ws.send('test')
    }

    // Вывод ошибки
    ws.onerror = (e) => {
        console.log('error: ' + e.message)
    }

    // Реконнект
    ws.onclose = () => {
        console.log('ws disconnected')
        setTimeout(() => wsStart(wsURL), 5000)
    }

    // Получили сообщение
    ws.onmessage = (e) => {
        eventsStore.setEvents(e.data)
    }
}

wsStart(wsURL)