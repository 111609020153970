export const minInHour = 60
export const hoursStart = 3
export const totalHoursADay = 18
export const minInterval = 15

export const FREE_FALL = 'Падение человека!'
export const SOS = 'Тревожная кнопка!'
export const SHARP_BLOW = 'Резкий удар!'
export const PULSE = 'Просто пульс'

export const ALERT_NO_ACTIVITY = 'ALERT_NO_ACTIVITY (нет активности)'
export const WATCH_REMOVAL = 'WATCH_REMOVAL (часы были сняты)'
export const ALERT_FREE_FALL = 'ALERT_FREE_FALL (свободное падение)'
export const ALERT_SHARP_BLOW = 'ALERT_SHARP_BLOW (резкий удар)'
export const ALERT_SOS = 'ALERT_SOS (тревожная кнопка)'
export const ALERT_PULSE = 'ALERT_PULSE (пульс)'