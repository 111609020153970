import React from "react";
import { useLocation } from "react-router-dom";
import "./card.scss";
import photo from "../../assets/images/photo.svg";

const PersonContainer = ({
  fullname,
  position,
  session,
  activeSessionDuration,
}) => {
  const location = useLocation();
  const { pathname } = location;

  const isLate = session?.schedule ? session.schedule.isLate : ""

  return (
    <div className="person-container d-flex align-items-center">
      <div className="person-container__img">
        <img src={photo} alt="" />
      </div>
      <div className="person-info">
        <p className="person">
          <span className="person__name fw-bold">{fullname}</span>{" "}
          {pathname === "/" ? (
            <span className="person__shift-time">
              {session?.isActive && activeSessionDuration
                ? "На смене " + activeSessionDuration
                : ""}
            </span>
          ) : (
            ""
          )}
        </p>
        <p className="person__post">{position}</p>
        <div className="person__shift">
          <div className="shift-list">
            <p>
              {session?.start && (
                    <span>
                      Начал смену в {" "}
                      <span className="fw-bold">{session?.start} </span>

                      {
                        isLate && <span className="text-danger fw-bold">(позже на {session?.schedule.lateTime})</span>
                      }
                    </span>
                  )}
              {
                pathname === "/" && !session?.isActive && session?.end && (
                  <span>
                    {" "}
                    завершил в <span className="fw-bold">{session?.end}</span>
                  </span>
                )}
                {!session?.start && !session?.end && (
                  <span>Нет данных о смене</span>
                )
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonContainer;
