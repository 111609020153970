export const baseURL = 'http://79.174.13.47:8001'

export default class Api {
    _getResponse = (res) => {
        if (res.ok) {
            return res.json()
        } else {
            throw new Error('Что-то пошло не так...')
        }
    }

    getData = async (path) => {
        try {
            const request = await fetch(`${baseURL}/${path}`)
            const response = await this._getResponse(request)
            return response
        } catch(error) {
            console.log(error)
        }
    }

    getDataAll = async (paths) => {
        try {
            const requests = await Promise.all(paths.map(path => fetch(`${baseURL}/${path}`)))
            const responses = await Promise.all(requests.map(res => this._getResponse(res)))
            return responses
        } catch(error) {
            console.log(error)
        }
    }

    changeData = async (path, action, id) => {
        const method = action === 'finish'
                        ? 'POST'
                        : 'PUT'

        const myHeaders = {
            method: method,
            headers: {
            'Content-Type': 'application/json;charset=utf-8'
            },
            // body: JSON.stringify('')
        }

        try {
            const request = await fetch(`${baseURL}/${path}/${action}?ids=${id}`, myHeaders)
            await this._getResponse(request)
        } catch(error) {
            console.log(error)
            console.log(error.message)
        }
    }
}