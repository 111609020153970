import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import "./select.scss";

const SelectMenu = ({ options, selectedOption, handleSelectedOption }) => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const setOption = (e) => {
    const target = e.target;

    if (location.pathname === "/") {
      const selectedDate = {
        day: target.innerHTML,
        date: target.dataset.date,
      };
      handleSelectedOption(selectedDate);
    }

    if (location.pathname === "/tables") {
      handleSelectedOption(target.innerHTML);
    }
  };

  return (
    <div className="select-container" onClick={toggleOpen}>
      <div
        className="select-container__button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="true"
        aria-expanded="false"
      >
        {selectedOption && selectedOption.day
          ? selectedOption.day
          : selectedOption.month}
      </div>
      <ul
        className={`dropdown-menu ${isOpen && "show"}`}
        aria-labelledby="defaultDropdown"
      >
        {options.map((option) => {
          return (
            <li
              className="dropdown-menu__item d-flex align-items-center"
              onClick={setOption}
              data-date={option.date ? option.date : ""}
              key={option.date ? option.date : option}
            >
              {option.day ? option.day : option}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SelectMenu;
