import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { inject, observer } from 'mobx-react';

import './assets/styles/normalize.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/styles.scss'

import Header from './components/Header';
import Workers from './components/Workers';
import Shift from './components/Shift';
import FormLogin from './components/FormLogin'
import Tables from './components/Tables';

function App({
  initConstData,
  getRequests,
  getActiveSessions,
  getWorkers
}) {
  useEffect(() => {
    getWorkers()
    initConstData()
    getRequests()
    getActiveSessions()

    const interval = setInterval(() => {
      getRequests()
      getActiveSessions()
    }, 5000);

    return () => clearInterval(interval)
  }, [])

  return (
    <BrowserRouter>
      <div className='app'>
        <Header />
        <div className="main">
          <Routes>
            <Route exact path="/shift" element={<Shift />} />
            <Route exact path="/" element={<Workers />} />
            <Route exact path="/tables" element={<Tables />} />
            <Route exact path="/login" element={<FormLogin />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default inject(({ orgStore, shiftsStore }) => {
  const { initStore, initConstData, getWorkers } = orgStore
  const { getRequests, getActiveSessions } = shiftsStore

  return {
    initConstData,
    initStore,
    getRequests,
    getActiveSessions,
    getWorkers
  }
})(observer(App))
