import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { observer, inject } from "mobx-react";

import "./shift.scss";
import ConfirmationCard from "./ConfirmationCard";

const Shift = ({ dangerStatus, activeSessions, requests, isLoggedIn }) => {
  const container = dangerStatus
    ? `d-flex flex-column align-items-center shift-container shift-container_top-padding`
    : `d-flex flex-column align-items-center shift-container`;

  return isLoggedIn ? (
    <div className={container}>
      <div>
        <h4 className="title shift-container__title">
          Подтверждение выхода на смену
        </h4>
      </div>
      <div className="requests-container d-flex flex-column align-items-center justify-content-center">
        {requests.map((r) => {
          return (
            <ConfirmationCard
              key={r.id}
              worker={r.worker}
              isActive={r.isActive}
              isApproved={r.isApproved}
              id={r.id}
            />
          );
        })}
      </div>
      <div className="active-container d-flex flex-column">
        {activeSessions?.map((s) => {
          return (
            <ConfirmationCard
              key={s.id}
              worker={s.worker}
              isActive={s.isActive}
              isApproved={s.isApproved}
              start={s.dateBegin}
              end={s.dateEnd}
              id={s.id}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default inject(({ eventsStore, shiftsStore, authData }) => {
  const { dangerStatus } = eventsStore;
  const { activeSessions, requests, getRequests } = shiftsStore;
  const { isLoggedIn } = authData;

  return {
    dangerStatus,
    activeSessions,
    requests,
    getRequests,
    isLoggedIn,
  };
})(observer(Shift));
