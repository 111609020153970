import { format, addMinutes, setHours } from 'date-fns'
import {useState, useEffect} from 'react'
import {getQueryDate, getTime} from '../helpers/helpers'
import Api from '../utils/api/api'
import * as constants from '../utils/constants'

const api = new Api()
const {
    ALERT_NO_ACTIVITY,
    WATCH_REMOVAL,
    ALERT_FREE_FALL,
    ALERT_SHARP_BLOW,
    ALERT_SOS,
    ALERT_PULSE
} = constants

export const useWorkerEvents = (
    fromDT,
    devID
) => {
    // Форматируем дату для запроса событий пришедших с устройства.
    // По дефолту ставим 06:00:00 дня (т.к. предполагаем, что это начало раб. дня). Формат даты для запроса в api: 2021-01-01 00:00:00
    const queryDate = format(
        setHours(new Date(fromDT), 6),
        "yyyy-MM-dd 06:00:00"
    )

    const [workerEvents, setWorkerEvents] = useState([])

    const [triggers, setTriggers] = useState([])
    const [isTriggersEnabled, setIsTriggersEnabled] = useState(true)

    const [events, setEvents] = useState([])

    const [totalEvents, setTotalEvents] = useState([])

    const getWorkerEvents = async (date) => {
        try {
            if (date && devID) {
                const data = await api.getData(`analytics/emergency/alert_any?fromDT=${date}&deviceId=${devID}&offset=0&limit=100`)
                
                const thisDayData = data?.sort((a,b) => new Date(a.receivedAt) - new Date(b.receivedAt))
                .filter(({ receivedAt }) => getQueryDate(receivedAt) === fromDT)

                setEvents([...events, ...thisDayData])
            }
        } catch(error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (events.length >= 100 && ((events.length % 100) === 0)) {
            const lastDate = new Date(events[events.length - 1].receivedAt)
            const newQueryDate = format(addMinutes(lastDate, 1), `yyyy-MM-dd HH:mm:00`)
            getWorkerEvents(newQueryDate)
        } else {
            handleWorkerEvents(events)
        }
    }, [events])

    const filterFunc = (array, type) => {
        return array?.filter(({emergencyType}) => emergencyType === type)
    }

    const setName = (val) => {
        switch (val) {
          case WATCH_REMOVAL:
            val = {
                critical: false,
                name: "Снятие браслета"
            }
            break;
          case ALERT_NO_ACTIVITY:
            val = {
                critical: false,
                name: "Сон/бездействие"
            }
            break;
          case ALERT_FREE_FALL:
            val = {
                critical: true,
                name: "Свободное падение"
            }
            break;
          case ALERT_SHARP_BLOW:
            val = {
                critical: false,
                name: "Резкий удар"
            }
            break;
          case ALERT_SOS:
            val = {
                critical: true,
                name: "Активация тревожной кнопки"
            }
            break;
            case ALERT_PULSE:
            val = {
                critical: true,
                name: "Высокий пульс"
            }
            break;
          default:
            val = {
                critical: false,
                name: "Н/Д"
            }
        }
        return val;
      };

    const handleWorkerEvents = (data) => {
        const thisDayDataObj = {
            [ALERT_NO_ACTIVITY]: filterFunc(data, ALERT_NO_ACTIVITY),
            [WATCH_REMOVAL]: filterFunc(data, WATCH_REMOVAL),
            [ALERT_FREE_FALL]: filterFunc(data, ALERT_FREE_FALL),
            [ALERT_SHARP_BLOW]: filterFunc(data, ALERT_SHARP_BLOW),
            [ALERT_SOS]: filterFunc(data, ALERT_SOS)
        }

        setWorkerEvents(thisDayDataObj)

        handleSetTriggers(thisDayDataObj)

        const fomattedData = data?.map(d => {
            return {
                ...d,
                emergencyName: setName(d.emergencyType),
                time: getTime(new Date(d.receivedAt))
            }
        })
        setTotalEvents(fomattedData)
    }
    
    const setEmergencyName = (array, val) => {
        if (val?.length) {
            switch(val[0].emergencyType) {
                case WATCH_REMOVAL:
                    array.push({
                        id: val[0].id,
                        critical: false,
                        action: 'Снятие часов'
                    })
                break
                case ALERT_NO_ACTIVITY:
                    array.push({
                        id: val[0].id,
                        critical: false,
                        action: 'Сон/\nбездействие'
                    })
                break
                case ALERT_FREE_FALL:
                    array.push({
                        id: val[0].id,
                        critical: true,
                        action: 'Падение'
                    })
                break
                case ALERT_SHARP_BLOW:
                    array.push({
                        id: val[0].id,
                        critical: false,
                        action: 'Резкий удар'
                    })
                break
                case ALERT_SOS:
                    array.push({
                        id: val[0].id,
                        critical: true,
                        action: 'SOS'
                    })
                break
                default:
                    array.push({
                        id: 1234567,
                        critical: false,
                        action: 'Н/Д'
                    })
            }
        }
        return array
    }

    const handleSetTriggers = (obj) => {
        let triggersArray = []
        Object.values(obj).forEach(val => setEmergencyName(triggersArray, val))

        setTriggers(triggersArray)
    }
    
    useEffect(() => {
        // Данные по событиям запрашиваются каждые 5 секунд, если открыт сегодняшний день
        getWorkerEvents(queryDate);

        const today = getQueryDate(new Date())

        const interval = setInterval(() => {
            if (queryDate.slice(0, 10) === today) {
                getWorkerEvents(queryDate);
            }
        }, 5000)

        return () => clearInterval(interval)
    }, [queryDate])

    // Функция изменения состояния отображения триггеров
    // В случае отсутствия данных в графиках триггеры не будут отображаться
    const handleIsTriggersEnabled = (isDataEnabled) => {
        // isDataEnabled - из дочернего компонента ChartsContainer получено путем поднятия состояния
        // Если хотя бы есть одни из данных, то триггеры будут отображться
        const isData = Object.values(isDataEnabled).some(val => !!val)

        setIsTriggersEnabled(isData)
    }

    return [workerEvents, handleIsTriggersEnabled, triggers, isTriggersEnabled, totalEvents]
}