import {
    format,
    startOfDay,
    intlFormat,
    intervalToDuration,
    formatDuration
} from 'date-fns';
import ruLocale from "date-fns/locale/ru";
import * as constants from '../utils/constants'
const {
    ALERT_NO_ACTIVITY,
    WATCH_REMOVAL,
    ALERT_FREE_FALL,
    ALERT_SHARP_BLOW,
    ALERT_SOS
} = constants

const ru = {
    locale: ruLocale
}

export const getDayOfMonth = (date) => {
    return format(new Date(date), "dd MMMM", ru)
}

export const getStartOfDay = (date) => {
    return startOfDay(new Date(date))
}

export const getTime = (date) => {
    return format(new Date(date), "H:mm", ru)
}

export const getHours = (date) => {
    return format(new Date(date), "H", ru)
}

export const sessionDuration = (start, end) => {
    const interval = intervalToDuration({
        start: new Date(start),
        end: new Date(end)
    })
    const duration = formatDuration(
        interval,
        {
            locale: ruLocale,
            format: ['days', 'hours', 'minutes']
        })
    return duration
}

export const lateDuration = (start, end) => {
    const interval = intervalToDuration({
        start: new Date(start),
        end: new Date(end)
    })

    const duration = formatDuration(
        interval,
        {
            locale: ruLocale,
            format: ['hours', 'minutes']
        })
    return duration
}

export const getQueryDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd')
}

export const sortDates = (array) => {
    return array?.sort((a, b) => a.dateBegin - b.dateBegin)
}

export const getMonth = (date) => {
    return intlFormat(new Date(date), {
        month: 'long',
        year: 'numeric'
    }, ru)
}

// Оставляем уникальные значения в массиве объектов
export const getUniqueObjects = (array) => {
    return array?.filter((v, i, a) => a.indexOf(v) === i)
}

export const getWorkerSessions = (array) => {
    return array?.map(s => {
        const worker = s.workers?.[0]
        const obj = {
            start: s.start,
            end: s.end,
            day: s.day,
            workerId: worker.id
        }
        return obj
    })
}

export const getUniqueWorkersIds = (array) => {
    return [...new Set(array?.map(s => s.workerId))]
}

export const getDayWorkers = (array) => {
    return array?.map(obj => obj.workers).flat()
}

export const getWord = (word) => {
    return word?.[0].toUpperCase() + word?.substring(1)
}

export const colorize = (values) => {
    return values?.map(el => {
        if (el < 2) {
            return 'rgba(196, 196, 196, 1)'
        } else if (el >= 2 && el <= 3) {
            return 'rgba(236, 10, 10, 1)'
        } else if (el > 3 && el < 5) {
            return 'rgba(234, 91, 10, 1)'
        } else if (el >= 5 && el < 6) {
            return 'rgba(255, 214, 0, 1)'
        } else if (el >= 6 && el < 8) {
            return 'rgba(230, 234, 10, 1)'
        } else {
            return 'rgba(122, 234, 10, 1)'
        }
    })
}

const decimalAdjust = (type, value, exp) => {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

// Decimal floor
export const floor10 = (value, exp) => decimalAdjust('floor', value, exp);
// Decimal ceil
export const ceil10 = (value, exp) => decimalAdjust('ceil', value, exp);

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is exclusive and the minimum is inclusive
}

export const setEmergencyName = (array, val) => {
    if (val?.length) {
        switch (val[0].emergencyType) {
            case WATCH_REMOVAL:
                array.push({
                    id: val[0].id,
                    critical: true,
                    action: 'Снятие часов'
                })
                break
            case ALERT_NO_ACTIVITY:
                array.push({
                    id: val[0].id,
                    critical: true,
                    action: 'Сон/\nбездействие'
                })
                break
            case ALERT_FREE_FALL:
                array.push({
                    id: val[0].id,
                    critical: true,
                    action: 'Падение'
                })
                break
            case ALERT_SHARP_BLOW:
                array.push({
                    id: val[0].id,
                    critical: true,
                    action: 'Резкий удар'
                })
                break
            case ALERT_SOS:
                array.push({
                    id: val[0].id,
                    critical: true,
                    action: 'SOS'
                })
                break
            default: array.push('')
        }
    }
    return array
}