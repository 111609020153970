import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { inject, observer } from "mobx-react";

import "./workers.scss";

import Events from "../Events";
import PersonCard from "../PersonCard";
import SelectMenu from "../Select/SelectMenu";

import { getQueryDate, getDayOfMonth } from "../../helpers/helpers";

const Workers = ({ dangerStatus, isLoggedIn, datesData, initConstData }) => {
  const [options, setOptions] = useState([]);

  const [selectedOption, setSelectedOption] = useState({
    day: "",
    date: "",
  });

  const [selectDateWorkers, setSelectDateWorkers] = useState([]);

  useEffect(() => {
    const today = getQueryDate(new Date());

    const dates = [...new Set(datesData.map((d) => Object.keys(d)))]
      .flat()
      .map((key) => {
        return {
          date: key,
          day: key === today ? "За сегодня" : getDayOfMonth(key),
        };
      });

    setSelectedOption((selectedOption) => ({
      ...selectedOption,
      date: dates[0]?.date,
      day: dates[0]?.day || "Загружаю...",
    }));

    setOptions(dates);

    if (datesData && datesData.length && dates && dates.length) {
      const workers = Object.values(
        datesData.find((obj) => Object.keys(obj)[0] === dates[0]?.date)
      )[0];

      setSelectDateWorkers(workers);
    }
  }, [datesData]);

  const handleSelectedOption = (date) => {
    setSelectedOption({
      day: date.day,
      date: date.date,
    });

    if (datesData && datesData.length) {
      const workers = Object.values(
        datesData.find((obj) => Object.keys(obj)[0] === date.date)
      )[0];

      setSelectDateWorkers(workers);
    }
  };

  const container = dangerStatus
    ? `w-100 d-flex page-container page-container_top-padding`
    : `w-100 d-flex page-container`;

  return isLoggedIn ? (
    <div className={container}>
      <div className="d-flex flex-column gap-4 workers-container">
        <div className="d-flex flex-wrap align-content-center">
          <div className="title-container">
            <h3 className="mb-0 title">Сотрудники</h3>
          </div>
          <SelectMenu
            options={options}
            selectedOption={selectedOption}
            handleSelectedOption={handleSelectedOption}
          />
        </div>
        {selectDateWorkers?.map((worker) => {
          return (
            <PersonCard
              key={worker.id + selectedOption.date}
              worker={worker}
              fromDT={selectedOption.date}
            />
          );
        })}
      </div>
      <aside className="aside">
        <Events />
      </aside>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default inject(({ orgStore, eventsStore, authData }) => {
  const { datesData, initConstData } = orgStore;
  const { dangerStatus } = eventsStore;
  const { isLoggedIn } = authData;

  return {
    dangerStatus,
    isLoggedIn,
    datesData,
    initConstData,
  };
})(observer(Workers));
