import React, { useEffect, useState } from "react";
import "./events.scss";
import Event from "./Event";
import { inject, observer } from "mobx-react";

const Events = ({ events }) => {
  const [lastEvents, setLastEvents] = useState([]);

  useEffect(() => {
    events.length && setLastEvents(events);
  }, [events]);

  return (
    <>
      <h4 className="title">События</h4>
      {lastEvents?.map((ev) => {
        return <Event key={ev.id} event={ev} />;
      })}
    </>
  );
};

export default inject(({ eventsStore }) => {
  const { events } = eventsStore;

  return {
    events,
  };
})(observer(Events));
